<template>
  <div class="DetailServices">

    <!-- Navbar & Carousel Start -->
    <div class="container-fluid position-relative p-0">

        <NavNavbarSI />


        
        <div class="container">
          <h1 class="mb-5" style="font-size: 35px; background: linear-gradient( #61C7F2, #F7A51B ); -webkit-background-clip: text; color: transparent;">
            FORMATIONS
          </h1>
        </div>    


          <div class="container p-5">
            <div class="mb-5 description" style="margin: 0px !important;">
            <div class="content">

              <div class="container nd-text" style="font-size: 22px !important;">
                <li> <span style="color: #F7A51B;">AGILES TELECOMS</span> propose  des formations théoriques et pratiques de qualité aux Personnels des Entreprises.</li>
                <li>Les collaborateurs d’<span style="color: #F7A51B;">AGILES TELECOMS</span> sont eux aussi formés dans l’optique de les rendre plus efficaces face aux attentes des clients.</li>
                <li><span style="color: #F7A51B;">AGILES TELECOMS</span> dispose d’un LAB virtuel sur lequel se font toutes les séances pratiques.</li>
              </div>

            </div>
          </div>


          <div class="content mt-4">
              <div class="row">
                <div class="card Formation_card">
                  Standards Telecoms mobile - 2G, 3G, 4G, 5G & IMS
                </div>
                <div class="card Formation_card">
                  Outils DevOps - vagant, Ansible, Teraform, etc.
                </div>
                <div class="card Formation_card">
                  Cloud & Orchestration - Openstack & Kebernetes
                </div>

                <div class="card Formation_card">
                  Virtualisation et Telco cloud
                </div>
                <div class="card Formation_card">
                  Sécurité des réseaux IP et Mobiles
                </div>
              </div>
          </div>


        </div>

    </div>
    <!-- content End -->
    


    <FooterView />
  </div>
</template>

<script>
// @ is an alias to /src
import NavNavbarSI from '@/components/NavNavbarSI.vue'
import FooterView from '@/components/FooterView.vue'

export default {
  name: 'DetailServices',
  components: {
    NavNavbarSI,
    FooterView
  }
}
</script>

<style scoped>
/* :root {
    --primary: #61C7F2;
    --secondary: #F7A51B;
    --light: #EEF9FF;
    --dark: #091E3E;
} */

div .Formation_card{
  text-align: center;
  justify-content: center !important;
  height: 80px !important;
  width: auto !important;
  margin: 5px !important;
  border: 2px solid #61C7F2 !important;
  border-radius: 15px !important;
  padding: 10px !important;
  font-size: 20px !important;
  font-weight: 500 !important;
}
</style>
