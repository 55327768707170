<template>
    <div class="DetailServices">
  
      <!-- Navbar & Carousel Start -->
      <div class="container-fluid position-relative p-0">
  
          <NavNavbarSI />
  
  
  
          <div class="container">
            <h1 class="mb-5" style="font-size: 30px; background: linear-gradient( #61C7F2, #F7A51B ); -webkit-background-clip: text; color: transparent;">
              ENERGIE
            </h1>
          </div>    
  
  
            <div class="container p-5">
              <div class="mb-5 description" style="margin: 0px !important;">
  
              <div class="row mb-5">
                <div class="col-md-9 mb-3">
                  <span style="color: #61C7F2; font-weight: 600;">AGILES TELECOMS</span> 
                  <span style="font-size: 20px;"> vous propose des solutions d'énergies solaires adaptées aux réseaux télécoms.</span>
                </div>

                <div class="div">
                    <h1>
                        Solutions d'alimentation hybride LionRock
                    </h1>
                </div>
  
                <div class="col-md-3">
                  <img src="../../../assets/img/agiles-mobilephone.png" width="150" alt="">
                </div>
              </div>
  
  
              <div class="content">
  
                <div class="row">
                  <div class="col-md-5" style="color: #61C7F2; padding-left: 30px;">
                    <h4 style="color: #61C7F2; font-size: 25px;">Plateforme de développement d'applications Android</h4>
                  </div>
  
                  <div class="col-md-7 nd-text" style="font-size: 22px; border-left: 2px solid #F7A51B; padding-left: 30px;">
                    Nous développons des applications Web et mobiles fournies sur des appareils Android à l'aide des meilleurs outils de développement d'applications Android, tels qu'Android Studio, Eclipse et IntelliJ.
                  </div>
                </div>
                <hr style="height: 1px;">
  
  
                <div class="row">
                  <div class="col-md-5" style="color: #61C7F2; padding-left: 30px;">
                    <h4 style="color: #61C7F2; font-size: 25px;">Plateforme de développement d'applications iOS</h4>
                  </div>
  
                  <div class="col-md-7 nd-text" style="font-size: 22px; border-left: 2px solid #F7A51B; padding-left: 30px;">
                    Nous créons des applications Web et mobiles fournies sur des appareils iOS à l'aide de XCode, AppCode, CodeRunner, RxSwift, Marvel, etc...
                  </div>
                </div>
                <hr style="height: 1px;">
  
  
                <div class="row">
                  <div class="col-md-5" style="color: #61C7F2; padding-left: 30px;">
                    <h4 style="color: #61C7F2; font-size: 25px;">Plateforme de développement d'applications Windows</h4>
                  </div>
  
                  <div class="col-md-7 nd-text" style="font-size: 22px; border-left: 2px solid #F7A51B; padding-left: 30px;">
                    Nos services de développement d'applications Windows proposent des conceptions innovantes, des processus rentables et des expériences utilisateur immersives à l'aide des frameworks Microsoft Visual Studio et .NET.
                  </div>
                </div>
                <hr style="height: 1px;">
  
              </div>
  
            </div>
          </div>
  
      </div>
      <!-- content End -->
      
  
  
      <FooterView />
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import NavNavbarSI from '@/components/NavNavbarSI.vue'
  import FooterView from '@/components/FooterView.vue'
  
  export default {
    name: 'DetailServices',
    components: {
      NavNavbarSI,
      FooterView
    }
  }
  </script>
  
  <style scoped>
  /* :root {
      --primary: #61C7F2;
      --secondary: #F7A51B;
      --light: #EEF9FF;
      --dark: #091E3E;
  } */
  </style>
  