<template>
    <div class="Agiles_solution_items_one">
  
      <!-- Navbar & Carousel Start -->
      <div class="container-fluid position-relative p-0">
  
          <NavNavbarSI />


        <!-- AS detail Start -->
        <div class="wow fadeInUp" data-wow-delay="0.1s">
            <div class="container p-5">
                <!-- AS Detail Start -->
                <div class="mb-5" style="margin: 0px !important;">

                    <h1 class="mb-4" style="margin-bottom: 115px !important; font-size: 50px; background: linear-gradient( #61C7F2, #F7A51B ); -webkit-background-clip: text; color: transparent;">
                        <center>WIFI OFFLOAD</center>
                    </h1>

                    <div>
                        <span class="Annonce mb-4"> 
                            Intégration du domaine WiFi dans l'infrastructure réseau 3G / 4G <br>

                            Réutilisation économique des ressources du réseau WiFi              
                        </span>
                    </div>
                    

                    <h3 class="Titre" style="margin-top: 65px !important; margin-bottom: 18px !important;">
                        Présentation du système
                    </h3>
                    


                    <div class="desc__titre">
                        <p class="Titre__items nd-text">
                            <span style="color: #61C7F2;"></span> 
                            La demande croissante d'accès à Internet oblige les opérateurs mobiles à faire d'énormes investissements dans l'infrastructure des réseaux. 
                            La technologie Wi-Fi est une excellente alternative pour les opérateurs pour fournir un accès Internet haut débit pour remplacer les ressources 
                            limitées du spectre 3G / 4G ou LTE. La réutilisation des ressources Wi-Fi est apparemment le meilleur moyen de satisfaire la demande de connexion haut débit. <br><br>


                            PROTEI WIX est une solution avancée de déchargement WiFi éprouvée sur le marché permettant aux opérateurs de réseaux mobiles d'organiser l'accès grâce à 
                            l'intégration de domaine WiFi dans l'infrastructure réseau. La plate-forme permet aux opérateurs mobiles non seulement de décharger le trafic haut débit, 
                            mais permet également l'itinérance inter-standard, donnant aux abonnés la capacité unique de recevoir des appels et des SMS sur leur propre numéro de téléphone 
                            tout en étant enregistrés sur le réseau WiFi via la technologie VoIP. <br><br>


                            Avec l'aide de la plate-forme WIX, les opérateurs peuvent déployer des services FMC efficaces en bande passante, offrant aux entreprises clientes la possibilité 
                            d'utiliser le WiFi à l'intérieur du bureau et la 3G / 4G à l'extérieur avec le même MSISDN et le même compte de facturation dans les deux réseaux. La plate-forme 
                            WIX est également capable de s'intégrer aux agrégateurs WLAN, ce qui permet aux abonnés d'utiliser le Wi-Fi dans le monde entier tout en payant le service à partir 
                            d'un compte de réseau domestique (roaming WiFi). La plate-forme fournit une facturation en temps réel de la transmission de données et des appels vocaux, en retirant 
                            le crédit du compte mobile principal des abonnés.
                        </p>
                        
                    </div><br><br>


                    <div class="desc__avantages">

                        <h3 class="Titre">Avantages clés</h3>
                        <ul style="font-size: 21px !important; color: #052E4A;">
                            <li>Création de compte via un système en libre-service, SMS ou IVR</li>
                            <li>Accès aux services pour les abonnés aux réseaux GSM / Wi-Fi</li>
                            <li>Diverses options d'authentification des utilisateurs (services de portail WEB, carte à gratter, SMS, IVR, EAP-SIM ou EAP-TLS)</li>
                            <li>Compte utilisateur intégré pratique</li>
                            <li>Contrôle dynamique des paramètres de session</li>
                            <li>Intégration avec les réseaux partenaires et les partenaires itinérants</li>
                            <li>Tarification en fonction de la localisation de l'utilisateur</li>
                        </ul>
                    </div>
                    
                </div>
                <!-- AS Detail End -->
            </div> 
        </div>
        <!-- AS detail End -->

          
      </div>
      <!-- Navbar & Carousel End -->
      
  
      <FooterView />
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import NavNavbarSI from '@/components/NavNavbarSI.vue'
  import FooterView from '@/components/FooterView.vue'
  
  export default {
    name: 'Agiles_solution_items_one',
    components: {
        NavNavbarSI,
      FooterView
    }
  }
  </script>
  
  <style scoped>

    .Annonce{
        font-size: 20px; font-weight: 400; 
    }
    .Titre{
        color: #F7A51B; font-weight: 500;
    }
    .Sous__titre{
        color: #052E4A; 
    }
    .Titre__items{
        font-size: 21px;
    }

  </style>