<template>
    <div class="container-fluid">
      <div class="row">
  
        <SidebarDash />
  
        <!-- Contenu Principal -->
        <div class="col">
            <main class="content">

                <h6 class="mb-5 mt-5" style="color: #F7A51B;">----------DÉTAILS DE L'ACTIVITÉ</h6>
                

                <div class=" justify-content-center ">

                    <div class="container">
              <div class="row g-5">
  
                  <div class="container-fluid wow fadeInUp" data-wow-delay="0.1s">
                      <div class="container ">
                          <div class="row">
                              <!-- Blog detail Start -->
                              <div class="col-12">

                                <div class="mb-5">
                                    <h1 class="mb-4">Ceci est les titre clita sed et lorem tempor duo</h1>

                                    <img class=" img-fluid w-100 rounded mb-5" src="../../../../assets/img/apropos-ac.jpg"  alt="">

                                    <h1 class="mb-4">Diam dolor est labore duo ipsum clita sed et lorem tempor duo</h1>
                                    <p>Sadipscing labore amet rebum est et justo gubergren. Et eirmod ipsum sit diam ut
                                        magna lorem. Nonumy vero labore lorem sanctus rebum et lorem magna kasd, stet
                                        amet magna accusam consetetur eirmod. Kasd accusam sit ipsum sadipscing et at at
                                        sanctus et. Ipsum sit gubergren dolores et, consetetur justo invidunt at et
                                        aliquyam ut et vero clita. Diam sea sea no sed dolores diam nonumy, gubergren
                                        sit stet no diam kasd vero.</p>
                                    <p>Voluptua est takimata stet invidunt sed rebum nonumy stet, clita aliquyam dolores
                                        vero stet consetetur elitr takimata rebum sanctus. Sit sed accusam stet sit
                                        nonumy kasd diam dolores, sanctus lorem kasd duo dolor dolor vero sit et. Labore
                                        ipsum duo sanctus amet eos et. Consetetur no sed et aliquyam ipsum justo et,
                                        clita lorem sit vero amet amet est dolor elitr, stet et no diam sit. Dolor erat
                                        justo dolore sit invidunt.</p>
                                    <p>Diam dolor est labore duo invidunt ipsum clita et, sed et lorem voluptua tempor
                                        invidunt at est sanctus sanctus. Clita dolores sit kasd diam takimata justo diam
                                        lorem sed. Magna amet sed rebum eos. Clita no magna no dolor erat diam tempor
                                        rebum consetetur, sanctus labore sed nonumy diam lorem amet eirmod. No at tempor
                                        sea diam kasd, takimata ea nonumy elitr sadipscing gubergren erat. Gubergren at
                                        lorem invidunt sadipscing rebum sit amet ut ut, voluptua diam dolores at
                                        sadipscing stet. Clita dolor amet dolor ipsum vero ea ea eos.</p>
                                    <p>Voluptua est takimata stet invidunt sed rebum nonumy stet, clita aliquyam dolores
                                        vero stet consetetur elitr takimata rebum sanctus. Sit sed accusam stet sit
                                        nonumy kasd diam dolores, sanctus lorem kasd duo dolor dolor vero sit et. Labore
                                        ipsum duo sanctus amet eos et. Consetetur no sed et aliquyam ipsum justo et,
                                        clita lorem sit vero amet amet est dolor elitr, stet et no diam sit. Dolor erat
                                        justo dolore sit invidunt.</p>
                                </div>
                    <!-- Blog Detail End -->
    
                   
                    
                </div>
    
                              
                  
                          </div>
                      </div>
                  </div>
      
              </div>
          </div>
                    
                </div>

            </main>
        </div>

      </div>
  </div>
</template>

<script>
import SidebarDash from '@/components/SidebarDash.vue'
  
  export default {
    name: "VoirRessources",
    components: {
        SidebarDash
    },

    // data() {
    //     return {
    //         agileressource: null,
    //         agileressources: [],
    //         isLoading: false,
    //         selectedFile: null,
    //     };
    // },

        
    // mounted(){
    //     this.getDetRessources(this.$route.params.id);
    // },

    // methods: {
    //     async getDetRessources(id) {
    //         this.isLoading = true;
    //         await this.$store.dispatch("staticdata/fetchVoirRessources", id)
    //         .then(
    //             (response) => {
    //                 this.isLoading = false;
    //                 this.agileressource = response.data;
    //             },
    //             (error) => {
    //                 this.isLoading = false;
    //                 console.log("error--- ", error);
    //             }
    //         );
    //     },

    //   goToPage(newPage) {
    //     if (newPage >= 1 && newPage <= this.totalPages) {
    //       this.page = newPage;
    //     }
    //   }
    // }
 }

</script>

<style scoped>
    .content {
        margin-left: 250px; /* Largeur du sidebar */
        padding: 20px;
    }

    @media (max-width: 768px) {
    .sidebar {
        transform: translateX(-250px);
    }

    .sidebar.show {
        transform: translateX(0);
    }

    .content {
        margin-left: 0;
    }
    }
</style>
