<template>
    <div class="Agiles_solution_items_one">
  
      <!-- Navbar & Carousel Start -->
      <div class="container-fluid position-relative p-0">
  
          <NavNavbarSI />
          


        <!-- AS detail Start -->
        <div class="wow fadeInUp" data-wow-delay="0.1s">
            <div class="container p-5">
                <!-- AS Detail Start -->
                <div class="mb-5" style="margin: 0px !important;">

                    <h1 class="mb-4" style="margin-bottom: 115px !important; font-size: 50px; background: linear-gradient( #61C7F2, #F7A51B ); -webkit-background-clip: text; color: transparent;">
                        <center>LOCAL NUMBER FOR VISITORS</center>
                    </h1>

                    <div>
                        <span class="Annonce mb-4"> 
                            Accédez aux informations statistiques et aux CDR détaillés pour tous les appels traités. <br>
                            Routage d'appels intellectuels pour les itinérants entrants
                        </span>
                    </div>
                    

                    <h3 class="Titre" style="margin-top: 65px !important; margin-bottom: 18px !important;">
                        Présentation du système
                    </h3>
                    


                    <div class="desc__titre">
                        <p class="Titre__items nd-text">
                            Cette solution donne à l’Operateur la fonctionnalité innovante unique pour fournir un numéro local aux itinérants professionnels à long terme.
                            Le numéro peut être utilisé pour accepter les appels entrants avec la possibilité de sauvegarder l'activité du numéro principal. 
                            Il n'est pas nécessaire d'acheter un compte local séparé. Les itinérants économisent les coûts d'appels entrants coûteux et se rapprochent davantage
                            de leurs partenaires commerciaux en raison de <span style="color: #61C7F2;">« l’effet de présence »</span> 
                            (pas besoin de composer un numéro international depuis le pays où l'abonné réside actuellement). 
                            L'opérateur peut déployer la solution <span style="color: #61C7F2;">LNV</span> pour augmenter ses revenus en déployant de nouveaux services attractifs pour les itinérants entrants et en 
                            économisant du trafic sur le réseau. Le service <span style="color: #61C7F2;">PROTEI LNV</span> a été développé en pleine conformité avec les normes 
                            internationales et s'adapte parfaitement aux besoins des opérateurs en raison de ses fonctionnalités étendues et d'une intégration facile avec les réseaux 3G / 4G.
                        </p>
                    </div><br><br>


                    <div class="desc__avantages">

                        <h3 class="Titre">Avantages clés</h3>
                        <ul style="font-size: 21px !important; color: #052E4A;">
                            <li> Système puissant de maintenance et de rapport</li>
                            <li>Prise en charge de SS7 / ISUP, SS7 / MAP, SMPP <br> pour l'intagration avec le réseau</li>
                            <li>Évolutif horizontalement</li>
                            <li>Gestion du routage</li>
                            <li>Gestion des invites vocales du système</li>
                            <li>Système de journalisation puissant (génération CDR);</li>
                            <li>Configuration système dynamique</li>
                        </ul>

                    </div>
                    
                </div>
                <!-- AS Detail End -->
            </div> 
        </div>
        <!-- AS detail End -->

          
      </div>
      <!-- Navbar & Carousel End -->
      
  
      <FooterView />
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import NavNavbarSI from '@/components/NavNavbarSI.vue'
  import FooterView from '@/components/FooterView.vue'
  
  export default {
    name: 'Agiles_solution_items_one',
    components: {
      NavNavbarSI,
      FooterView
    }
  }
  </script>
  
  <style scoped>

    .Annonce{
        font-size: 20px; font-weight: 400; 
    }
    .Titre{
        color: #F7A51B; font-weight: 500;
    }
    .Sous__titre{
        color: #052E4A; 
    }
    .Titre__items{
        font-size: 21px;
    }

  </style>