<template>
    <div class="Agiles_activites">
  
      <!-- Navbar & Carousel Start -->
      <div class="container-fluid position-relative p-0">
  
          <NavNavbar />
          <div class="container-fluid bg-primary py-5 bg-header-ressources" style="margin-bottom: 90px;">
              <div class="row py-5">
                  <div class="col-12 pt-lg-5 mt-lg-5 text-center">
                      <router-link to="/agiles-store" class="h5 text-white m-2">Agiles Activités</router-link>
                      <font-awesome-icon icon="fa-regular fa-circle" :style="{ color: '#ffffff' }" />
                      <router-link to="/agiles-activites" class="h5 text-white m-2">Description</router-link>
                  </div>
              </div>
          </div>
  
  
          <h1 class="mb-5" style="font-size: 50px; background: linear-gradient( #61C7F2, #F7A51B ); -webkit-background-clip: text; color: transparent;">
            <center>AGILES ACTIVITÉS</center> 
          </h1>
          <center><h3 class="mb-5" style="color: #052E4A;">Découvrez nos activités</h3> </center><br>
  
  
      </div>
      <!-- content End -->
      
       <!-- Blog Start -->
       <div class="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div class="container">
              <div class="row g-5">
  
                  <div class="container-fluid wow fadeInUp" data-wow-delay="0.1s">
                      <div class="container py-5">
                          <div class="row">
                              <!-- Blog detail Start -->
                              <div class="col-lg-8">

                                <div class="mb-5">
                                    <img class=" img-fluid w-100 rounded mb-5" src="../../../assets/img/apropos-ac.jpg"  alt="">

                                    <h1 class="mb-4">Diam dolor est labore duo ipsum clita sed et lorem tempor duo</h1>
                                    <p class="nd-text">Sadipscing labore amet rebum est et justo gubergren. Et eirmod ipsum sit diam ut
                                        magna lorem. Nonumy vero labore lorem sanctus rebum et lorem magna kasd, stet
                                        amet magna accusam consetetur eirmod. Kasd accusam sit ipsum sadipscing et at at
                                        sanctus et. Ipsum sit gubergren dolores et, consetetur justo invidunt at et
                                        aliquyam ut et vero clita. Diam sea sea no sed dolores diam nonumy, gubergren
                                        sit stet no diam kasd vero.</p>
                                    <p class="nd-text">Voluptua est takimata stet invidunt sed rebum nonumy stet, clita aliquyam dolores
                                        vero stet consetetur elitr takimata rebum sanctus. Sit sed accusam stet sit
                                        nonumy kasd diam dolores, sanctus lorem kasd duo dolor dolor vero sit et. Labore
                                        ipsum duo sanctus amet eos et. Consetetur no sed et aliquyam ipsum justo et,
                                        clita lorem sit vero amet amet est dolor elitr, stet et no diam sit. Dolor erat
                                        justo dolore sit invidunt.</p>
                                    <p class="nd-text">Diam dolor est labore duo invidunt ipsum clita et, sed et lorem voluptua tempor
                                        invidunt at est sanctus sanctus. Clita dolores sit kasd diam takimata justo diam
                                        lorem sed. Magna amet sed rebum eos. Clita no magna no dolor erat diam tempor
                                        rebum consetetur, sanctus labore sed nonumy diam lorem amet eirmod. No at tempor
                                        sea diam kasd, takimata ea nonumy elitr sadipscing gubergren erat. Gubergren at
                                        lorem invidunt sadipscing rebum sit amet ut ut, voluptua diam dolores at
                                        sadipscing stet. Clita dolor amet dolor ipsum vero ea ea eos.</p>
                                    <p class="nd-text">Voluptua est takimata stet invidunt sed rebum nonumy stet, clita aliquyam dolores
                                        vero stet consetetur elitr takimata rebum sanctus. Sit sed accusam stet sit
                                        nonumy kasd diam dolores, sanctus lorem kasd duo dolor dolor vero sit et. Labore
                                        ipsum duo sanctus amet eos et. Consetetur no sed et aliquyam ipsum justo et,
                                        clita lorem sit vero amet amet est dolor elitr, stet et no diam sit. Dolor erat
                                        justo dolore sit invidunt.</p>
                                </div>
                    <!-- Blog Detail End -->
    
                    <!-- Comment List Start -->
                                    <!-- <div class="mb-5">
                                        <div class="section-title section-title-sm position-relative pb-3 mb-4">
                                            <h3 class="mb-0">Commentaires</h3>
                                        </div>
                                        <div class="d-flex mb-4">
                                            <div class="ps-3">
                                                <h6><a href="">John Doe</a> <small><i>01 Jan 2045</i></small></h6>
                                                <p>Diam amet duo labore stet elitr invidunt ea clita ipsum voluptua, tempor labore
                                                    accusam ipsum et no at. Kasd diam tempor rebum magna dolores sed eirmod</p>
                                                <button class="btn btn-sm btn-light">Répondre</button>
                                            </div>
                                        </div>
                                        <div class="d-flex ms-3 mb-4">
                                            <div class="ps-3">
                                                <h6><a href="">John Doe</a> <small><i>01 Jan 2045</i></small></h6>
                                                <p>Diam amet duo labore stet elitr invidunt ea clita ipsum voluptua, tempor labore
                                                    accusam ipsum et no at. Kasd diam tempor rebum magna dolores sed eirmod</p>
                                            </div>
                                        </div>
                                        <div class="d-flex ms-5 mb-4">
                                            <div class="ps-3">
                                                <h6><a href="">John Doe</a> <small><i>01 Jan 2045</i></small></h6>
                                                <p>Diam amet duo labore stet elitr invidunt ea clita ipsum voluptua, tempor labore
                                                    accusam ipsum et no at. Kasd diam tempor rebum magna dolores sed eirmod</p>
                                            </div>
                                        </div>
                                    </div> -->
                    <!-- Comment List End -->
    
                    <!-- Comment Form Start -->
                                <!-- <div class="bg-light rounded p-5">
                                    <div class="section-title section-title-sm position-relative pb-3 mb-4">
                                        <h3 class="mb-0">Laissez un commentaire</h3>
                                    </div>
                                    <form>
                                        <div class="row g-3">
                                            <div class="col-12 col-12">
                                                <input type="text" class="form-control bg-white border-0" placeholder="Nom & Prénoms" style="height: 55px;">
                                            </div>
                                            
                                            <div class="col-12">
                                                <textarea class="form-control bg-white border-0" rows="5" placeholder="Commentaire"></textarea>
                                            </div>
                                            <div class="col-12">
                                                <button class="btn btn-primary w-100 py-3" type="submit">Envoyer</button>
                                            </div>
                                        </div>
                                    </form>
                                </div> -->
                    <!-- Comment Form End -->
                </div>
    
                              <!-- Blog detail End -->
  
                              <div class="col-lg-4">
                                  <!-- Search Form Start -->
                                  <div class="mb-5 wow slideInUp" data-wow-delay="0.1s">
                                      <div class="input-group">
                                          <input type="text" class="form-control p-3" placeholder="Mots clés">
                                          <button class="btn btn-primary px-4"><i class="bi bi-search"></i></button>
                                      </div>
                                  </div>
                                  <!-- Search Form End -->
      
                                  <!-- Category Start -->
                                  <div class="mb-5 wow slideInUp" data-wow-delay="0.1s">
                                      <div class="section-title section-title-sm position-relative pb-3 mb-4">
                                          <h3 class="mb-0">Categories</h3>
                                      </div>
                                      <div class="link-animated d-flex flex-column justify-content-start">
                                          <a class="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="#"><i class="bi bi-arrow-right me-2"></i>Photos</a>
                                          <a class="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="#"><i class="bi bi-arrow-right me-2"></i>WorkShop</a>
                                          <a class="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="#"><i class="bi bi-arrow-right me-2"></i>After work</a>
                                          <a class="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="#"><i class="bi bi-arrow-right me-2"></i>Activités</a>
                                          <a class="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="#"><i class="bi bi-arrow-right me-2"></i>Formations</a>
                                      </div>
                                  </div>
                                  <!-- Category End -->
                              </div>
                  
                          </div>
                      </div>
                  </div>
      
              </div>
          </div>
      </div>
      <!-- Blog End -->
  
      <FooterView />
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import NavNavbar from '@/components/NavNavbar.vue'
  import FooterView from '@/components/FooterView.vue'
  
  
  export default {
    name: 'Agiles_activites',
    components: {
      NavNavbar,
      FooterView
    },
  
  
  
  }
  </script>
  
  
  <style scoped>
  
    /* --bleu: #61C7F2;
    --orange: #F7A51B;
    --light: #EEF9FF;
    --dark: #091E3E; */
  
  
    .card {
    border: 2px solid #F7A51B;
    /* padding: 50px !important; */
    overflow: hidden;
  }
  .border-blue{
    border: 2px solid #61C7F2;
    padding: 5px !important;
    overflow: hidden;
  }
  
  /* ////// le modal */
  .modal-window {
  position: fixed;
  background-color: rgba(170, 170, 170, 0.681) !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
  }
  .modal-window:target {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  }
  .modal-window > div {
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2em;
  background: white;
  }
  .modal-window header {
  font-weight: bold;
  }
  .modal-window h1 {
  font-size: 150%;
  margin: 0 0 15px;
  }
  
  .modal-close {
  color: #aaa;
  line-height: 50px;
  font-size: 55%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 70px;
  text-decoration: none;
  }
  .modal-close:hover {
  color: black;
  }
  
  /* Demo Styles */
  
  
  .modal-window > div {
  border-radius: 1rem;
  }
  
  .modal-window div:not(:last-of-type) {
  margin-bottom: 15px;
  }
  
  small {
  color: lightgray;
  }
  
  
  </style>
  