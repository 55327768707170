<template>
    <div class="Agiles_solution_items_one">
  
      <!-- Navbar & Carousel Start -->
      <div class="container-fluid position-relative p-0">
  
          <NavNavbarSI />
          

        <!-- AS detail Start -->
        <div class="wow fadeInUp" data-wow-delay="0.1s">
            <div class="container p-5">
                <!-- AS Detail Start -->
                <div class="mb-5" style="margin: 0px !important;">

                    <h1 class="mb-4" style="margin-bottom: 115px !important; font-size: 50px; background: linear-gradient( #61C7F2, #F7A51B ); -webkit-background-clip: text; color: transparent;">
                        <center>USSD GATEWAY</center>
                    </h1>

                    <h3 class="Titre" style="margin-top: 65px !important; margin-bottom: 18px !important;">
                        Présentation du système
                    </h3>
                    


                    <div class="desc__titre">
                        <p class="Titre__items nd-text">
                            <span style="color: #61C7F2;"></span> 
                            Le serveur PROTEI USSD échange des messages USSD entre les abonnés mobiles et les applications externes via les réseaux GSM. 
                            Avec le serveur PROTEI USSD, les opérateurs sont en mesure de fournir une demande de solde, l'activation de bons et d'autres 
                            services d'assistance à la clientèle de la manière la plus efficace et la plus pratique. Le serveur PROTEI USSD prend en charge 
                            le routage flexible des messages USSD basé sur les clés de service, le corps du message et la source de l'adresse MSC avec possibilité 
                            de politique d'accès et de gestion de la bande passante pour chaque application individuellement. Les niveaux USSD I et II sont pris en charge, 
                            ce qui permet de créer des services USSD de dialogue avec un menu USSD à plusieurs niveaux. SMPP v3.4 offre une intégration rapide et facile avec 
                            des fournisseurs de contenu externes et des applications externes. L'interface de charge en ligne est également prise en charge. <br><br>


                            PROTEI USSDS dispose d'outils intégrés pour la construction de menus USSD pratique et flexible et prend en charge les interfaces Open XML et ODBC
                            pour l'intégration avec des systèmes d'information et des bases de données externes. Différents abonnés peuvent accéder à différentes applications
                            en utilisant un seul numéro de service USSD. USSDS peut définir le type d'application non seulement à partir du numéro de service USSD, mais également 
                            à partir du numéro de l'expéditeur (CgPN) ou de l'identifiant d'un groupe d'expéditeurs. <br><br>


                            Le serveur USSD hautes performances avec une architecture distribuée permet l'échange de messages USSD entre les abonnés mobiles et les applications 
                            externes dans les réseaux GSM. Avec le serveur USSD, les opérateurs mobiles peuvent fournir aux abonnés un certain nombre de services attractifs : 
                            demande de solde, activation de cartes de paiement, gestion de profil et autres sous la forme la plus pratique pour les utilisateurs. 
                            Le serveur prend en charge des algorithmes de routage flexibles pour les messages USSD en tenant compte les identificateurs de service, 
                            le corps du message et les sources d'adresses MSC, ce qui vous permet de gérer les politiques d'accès et la bande passante individuellement pour 
                            chaque application. La prise en charge de la phase 1 et de la phase 2 de l'USSD est fournie, ce qui permet de créer des services USSD de dialogue 
                            avec des menus USSD à plusieurs niveaux. SMPP v3.4 permet une communication facile avec des fournisseurs de contenu externes. <br><br>


                            Le système fournit un constructeur de menu USSD visualisé et intégré avec la possibilité de s'intégrer à des systèmes d'information externes.
                            À l'aide de ce constructeur, l'opérateur peut à lui seul créer rapidement et efficacement de nouveaux services USSD ou modifier la structure du menu USSD. 
                            Pour interagir avec des systèmes et des bases de données externes, une prise en charge est fournie pour les interfaces Open XML et ODBC, ainsi que pour les 
                            interfaces de facturation des demandes USSD. Pour activer l'intégration avec les réseaux IMS, la prise en charge de la fonctionnalité USSI est fournie.
                        </p>
                        <!-- <p class="Titre__items">
                            La solution PROTEI Smart / Safe city est conçue pour créer un écosystème de ville intelligente avancé, 
                            identifier les vulnérabilités et menaces potentielles, identifier et prédire les menaces actuelles et potentielles 
                            liées à la sécurité publique des menaces de la municipalité / ville / province, optimiser la coordination entre tous 
                            les participants pendant gestion / élimination des urgences, permettant les communications entre les participants de 
                            toutes les initiatives de sécurité publique existantes via le bus unifié.
                        </p> -->
                    </div><br><br>


                    <div class="desc__avantages">

                        <h3 class="Titre">Avantages clés</h3>
                        <ul style="font-size: 21px !important; color: #052E4A;">
                            <li>Administration facile</li>
                            <li>Intégration simple avec des applications externes</li>
                            <li>Les plus hauts standards de fiabilité</li>
                            <li>L'installation ne nécessite aucune modification de l'infrastructure existante</li>
                            <li>Architecture réseau évolutive horizontalement</li>
                            <li>Configuration hautement flexible</li>
                            <li>Indication d'alarme</li>
                            <li>Prise en charge des phases 1 et 2 USSD</li>
                        </ul>
                        

                    </div>
                    
                </div>
                <!-- AS Detail End -->
            </div> 
        </div>
        <!-- AS detail End -->

          
      </div>
      <!-- Navbar & Carousel End -->
      
  
      <FooterView />
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import NavNavbarSI from '@/components/NavNavbarSI.vue'
  import FooterView from '@/components/FooterView.vue'
  
  export default {
    name: 'Agiles_solution_items_one',
    components: {
        NavNavbarSI,
      FooterView
    }
  }
  </script>
  
  <style scoped>

    .Annonce{
        font-size: 20px; font-weight: 400; 
    }
    .Titre{
        color: #F7A51B; font-weight: 500;
    }
    .Sous__titre{
        color: #052E4A; 
    }
    .Titre__items{
        font-size: 21px;
    }

  </style>