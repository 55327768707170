<template>
    <div class="container-fluid">
      <div class="row">
  
        <SidebarDash />
  
        <!-- Contenu Principal -->
        <div class="col">
            <main class="content">
  
                <h6 class="mb-3 mt-2" style="color: #F7A51B;">----------Liste des ressources</h6>

                <div class="">
                  <!-- <section v-if="loadingGetActivites">
                      <div class="row g-5">
                        <div class="col-12 text-center">
                          <h4>Affichage en cours ...</h4>
                        </div>
                      </div>
                  </section> -->
                
                  <div class="mt-5">

                    <!-- <section v-if="agileressource.length == 0">
                      <div class="row g-5">
                        <div class="col-12 text-center text-warning">
                          <h4>Aucune donnée disponible</h4>
                        </div>
                      </div>
                    </section> -->

                    <!-- <section v-else>

                      <div class="card mb-4" v-for="index in agileressource" :key="index.id">

                          <div class="card-header p-2">
                            <h3 class="text-center" style="font-weight: 600;">{{ index.title }}</h3>

                            <img v-bind:src="index.illustration" class="img-fluid mt-3 mb-5" alt=""> 

                            <div class="card-footer">
                              <a :href="index.fichier" target="_blank" style="color: #F7A51B; text-decoration: underline; text-transform: uppercase; font-weight: 900;">ICI Fichier à télécharger</a>
                            </div>

                          </div>

                      </div>

                    </section> -->

                    <div class="row">
                            <!-- Blog list Start -->
                            <div class="col-lg-8">
                                <div class="row g-5">
                                  <div class="col-md-6 wow slideInUp" data-wow-delay="0.1s">
                                    <div class="blog-item bg-light rounded overflow-hidden">
                                        <div class="blog-img position-relative overflow-hidden">
                                            <img class=" img-fluid" src="../../../../assets/img/apropos-ac.jpg"  alt="">
                                            <a class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4" href="">Photo</a>
                                        </div>
                                        <div class="p-4">
                                            <div class="d-flex mb-3">
                                                <small class="me-3"><i class="far fa-user text-primary me-2"></i>Agiles Telecoms</small>
                                                <small><i class="far fa-calendar-alt text-primary me-2"></i>10/12/2024</small>
                                            </div>
                                            <h4 class="mb-3" style="color: #F7A51B;">Séance de photo avec l'équipe AT</h4>
                                            
                                            <router-link to="/dtactivites" class="" href="">Voir détails <i class="bi bi-arrow-right"></i></router-link>
                                        </div>
                                    </div>
                                </div>
                    </div>
                    </div>
                    </div>

                      
                  </div>    
                </div>    
                        
            </main>
        </div>
        <!-- Contenu Principal -->
  
      </div>
    </div>
</template>
  


<script>
// @ is an alias to /src
import SidebarDash from '@/components/SidebarDash.vue'


export default {
  name: 'ListActivites',
  components: {
    SidebarDash
  },

  // data() {
  //   return {
  //     agileressource: [],
  //     loading: false,
  //     selectedFile: null,
  //     loadingGetActivites: false,
  //     apiressource: "",
  //   };
  // },

  // mounted() {
  //   this.agileressourceList();
  // },

  // methods: {
  //   async agileressourceList() {
  //     this.loadingGetAnnonce = true;
  //     await this.$store.dispatch("staticdata/fetchRessources").then(
  //       (response) => {
  //         this.agileressource = response.data;
  //         this.loadingGetRessource = false;
  //       },
  //       (error) => {
  //         console.log("error--- ", error);
  //         this.loadingGetRessource = false;
  //       }
  //     );
  //   },

  //   handleFileChange(event) {
  //       this.selectedFile = event.target.files[0];
  //   },
  // }

}
</script>
  
  
<style scoped>
  .content {
      margin-left: 250px; /* Largeur du sidebar */
      padding: 20px;
  }

  @media (max-width: 768px) {
  .sidebar {
      transform: translateX(-250px);
  }

  .sidebar.show {
      transform: translateX(0);
  }

  .content {
      margin-left: 0;
  }
  }

  .cadre {
    position: relative;

    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cadre.card {
    
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
  }
</style>