<template>
  <!-- Sidebar -->
  <div>
    <!-- Bouton de bascule pour mobile -->
    <button class="btn d-md-none" style="background-color: #F7A51B; color: white;" @click="toggleSidebar">Menu</button>
    
    <!-- Sidebar -->
    <div class="col-auto p-0">
      <div class="sidebar" :class="{ 'show': isSidebarVisible }" ref="sidebar">
        <ul class="nav flex-column">
          <!--  -->
          <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="dropdownAnnonces" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Annonces
          </a>
          <ul class="dropdown-menu" aria-labelledby="dropdownAnnonces">
            <li>
              <router-link to="/adoffre" class="dropdown-item" :class="{ active: $route.path === '/adoffre' }">Ajouter une annonce</router-link>
            </li>
            <li>
              <router-link to="/ltoffre" class="dropdown-item" :class="{ active: $route.path === '/ltoffre' }">Listes des annonces</router-link>
            </li>
          </ul>
        </li>

        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="dropdownCandidats" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Candidats
          </a>
          <ul class="dropdown-menu" aria-labelledby="dropdownCandidats">
            <li>
              <router-link to="/ltcdtformateur" class="dropdown-item" :class="{ active: $route.path === '/ltcdtformateur' }">Formateurs</router-link>
            </li>
            <li>
              <router-link to="/ltcdtannonces" class="dropdown-item" :class="{ active: $route.path === '/ltcdtannonces' }">Candidats postulés</router-link>
            </li>
            <li>
              <router-link to="/ltcdtspont" class="dropdown-item" :class="{ active: $route.path === '/ltcdtspont' }">Candidats spontanées</router-link>
            </li>
          </ul>
        </li>

        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="dropdownActivites" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Activites 
          </a>
          <ul class="dropdown-menu" aria-labelledby="dropdownActivites">
            <li>
              <router-link to="/adactivites" class="dropdown-item" :class="{ active: $route.path === '/adactivites' }">Ajouter une activités</router-link>
            </li>
            <li>
              <router-link to="/ltactivites" class="dropdown-item" :class="{ active: $route.path === '/ltactivites' }">Liste des activités</router-link>
            </li>
          </ul>
        </li>
        
        <!-- <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="dropdownRessources" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Ressources 
          </a>
          <ul class="dropdown-menu" aria-labelledby="dropdownRessources">
            <li>
              <router-link to="/adsrc01" class="dropdown-item" :class="{ active: $route.path === '/adsrc01' }">Ajouter une ressource</router-link>
            </li>
            <li>
              <router-link to="/adsrc-02" class="dropdown-item" :class="{ active: $route.path === '/adsrc-02' }">Liste des ressources</router-link>
            </li>
          </ul>
        </li> -->
          <!-- ... -->
        </ul>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      isSidebarVisible: false,
    };
  },
  mounted() {
    // Accéder à l'élément sidebar par référence
    const sidebar = this.$refs.sidebar;

    // Vérifier si l'élément sidebar existe avant d'ajouter l'événement
    if (sidebar) {
      sidebar.addEventListener('click', this.handleSidebarClick);
    }
  },
  methods: {
    toggleSidebar() {
      this.isSidebarVisible = !this.isSidebarVisible;
    },
    handleSidebarClick() {
      // Gérer le clic sur le sidebar ici
      console.log('Sidebar clicked!');
    }
  }
};
</script>


<style scoped>
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 250px;
  padding: 20px;
  background-color: #61C7F2;
  transition: transform 0.3s ease-in-out;
  overflow-y: auto;
}
.nav-link {
  color: white !important;
  text-transform: uppercase;
  font-weight: 500;
}
.nav-link.active {
  background-color: #F7A51B !important;
}
.dropdown-item.active, .dropdown-item:active{
  color: white !important;
  background-color: #F7A51B !important;
  border-radius: 0px 0px 20px 20px !important;
}

ul.dropdown-menu.show{
  padding: 0px !important;
}
.dropdown-menu{
  color: #61C7F2 !important;
  border-radius: 0px 0px 20px 20px !important;
}
.btn-dark {
    /* color: #fff; */
    background-color: transparent !important;
    border: none !important;
}

.dropdown-item{
  color: #61C7F2 !important;
}

@media (max-width: 768px) {
  .sidebar {
    transform: translateX(-250px);
  }

  .sidebar.show {
    transform: translateX(0);
  }

  .content {
    margin-left: 0;
  }

  .btn-primary {
    margin: 10px;
  }
}
</style>
