<template>
  <div class="home">

    <!-- Spinner Start -->
    <!-- <div id="spinner" class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner"></div>
    </div> -->
    <!-- Spinner End -->

    <!-- Navbar & Carousel Start -->
    <div class="container-fluid position-relative p-0">

        <NavNavbar />

        <div id="header-carousel" class="carousel slide carousel-fade" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img class="w-100" src="../assets/img/paque.jpg" alt="Image">
                    <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
                        <div class="p-3" style="max-width: 900px;">
                            <h1 class="display-4 text-white mb-md-4 animated zoomIn">
                                <span>BIENVENUE CHEZ <br> <span style="color: #61C7F2">AGILES TELECOMS!</span></span> 
                                <!-- <br><span style="background-color: rgb(7, 173, 7) !important;"> Félicitation! La Côte d'Ivoire est Champion d'Afrique</span> -->
                            </h1>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <img class="w-100" src="../assets/img/telfixe-ac.jpg" alt="Image">
                    <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
                        <div class="p-3" style="max-width: 900px;">
                            <h1 class="display-1 text-white mb-md-4 animated zoomIn">
                                <span style="background-color: #F7A51B;">SERVICES TELECOMS</span> 
                                <!-- <br><span style="background-color: #61C7F2 !important;">Recrutements</span> -->
                            </h1>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <img class="w-100" src="../assets/img/service-it.jpeg" alt="Image">
                    <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
                        <div class="p-3" style="max-width: 900px;">
                            <h1 class="display-1 text-white mb-md-4 animated zoomIn">
                                <!-- <span style="background-color: #F7A51B;">Solutions</span>  <br> -->
                                <span style="background-color: #61C7F2 !important;">SERVICES IT</span>
                            </h1>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <img class="w-100" src="../assets/img/energie-ac.jpeg" alt="Image">
                    <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
                        <div class="p-3" style="max-width: 900px;">
                            <h1 class="display-1 text-white mb-md-4 animated zoomIn">
                                <span style="background-color: #F7A51B;">ENERGIE</span> 
                                <!-- <span style="background-color: #61C7F2 !important;">Agiles</span> -->
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#header-carousel"
                data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#header-carousel"
                data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>
    </div>
    <!-- Navbar & Carousel End -->

    <!-- About Start -->
    <div class="container-fluid py-5  animated fadeInUp fadeInU">
        <div class="container py-5">

            <div class="Titre mb-2">
                <h1 class="retroshd"> A Propos de Nous </h1>
            </div>

            <div class="row g-5">
                <div class="col-lg-6 p-5">
                    <p class="nd-text" style="font-size: 20px;">
                        <strong>AGILES TELECOMS</strong> est une société intervenant dans les domaines de l’informatiques, des télécoms et de l'Energies.
                        <br> Nous accompagnons pleinement nos clients durant toutes les étapes de leurs projets, depuis l’étude initiale jusqu’à la mise en œuvre finale.
                    </p>
                    <div class="row g-0 About_li_fa">
                        <div class="col-sm-7 animated zoomIn">
                            <h5 class="mb-3" style="">
                                <font-awesome-icon icon="check" size="1x" :style="{ color: '#61C7F2' }" />
                                Le meilleur de l’industrie
                            </h5>
                            <h5 class="mb-3">
                                <font-awesome-icon icon="check" size="1x" :style="{ color: '#61C7F2' }" />
                                Un personnel professionnel
                            </h5>
                        </div>
                        <div class="col-sm-5 animated zoomIn">
                            <h5 class="mb-3">
                                <font-awesome-icon icon="check" size="1x" :style="{ color: '#61C7F2' }" />
                                A votre service
                            </h5>
                            
                            <h5 class="mb-3">
                                <font-awesome-icon icon="check" size="1x" :style="{ color: '#61C7F2' }" />
                                Primé
                            </h5>
                        </div>
                    </div>
                </div>
     
                <div class="col-lg-6 p-5 animated zoomIn" data-wow-delay="0.9s">

                    <div class="position-relative card MonCard">
                        <img class="img-fluid Img-border m-1" src="../assets/img/apropos-ac.jpg" alt="A-propos">
                    </div> 

                </div>
            </div>
        </div>
    </div>
    <!-- About End -->

    <!-- Services Start -->
    <div class="container-fluid py-5 animated fadeInUp fadeInU">
        <div class="container py-5">
        <div class="Titre mb-2">
            <h1 class="retroshd"> Découvrez nos services </h1>
        </div>

        <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style="font-size: 1.5rem; max-width: 800px;">
            En savoir plus sur nos domaines de compétences.
        </div>

        <div class="row">
            <div class="col-md-4 mb-4" v-for="service in services" :key="service.id">
            <div class="card services-card">
                <div class="service-text">
                    <h2>{{ service.titleFront }}</h2>
                    <hr/>
                    <p class="nd-text">{{ service.descriptionFront }}</p>
                    <br>
                    <ul class="p-0 m-0" style="list-style: none !important;">
                    <li v-for="(item, index) in service.frontItems" :key="index">
                        <font-awesome-icon icon="check" size="1x" :style="{ color: '#F7A51B' }" />
                        {{ item }}
                    </li>
                    </ul>
                </div>
            </div>
            </div>
        </div>

        <!-- Voir plus start -->
        <div class="text-center mt-4">
            <router-link to="/notre-catalogue">
            <button class="btn btn-outline-primary">Voir notre catalogue</button>
            </router-link>
        </div>
        <!-- Voir plus end -->
        </div>
    </div>
    <!-- Services end -->

    <!-- Features Start -->
    <div class="container-fluid py-5 animated fadeInUp fadeInU">
        <div class="container py-5">

            <div class="Titre mb-2">
                <h1 class="retroshd"> Pourquoi nous choisir </h1>
            </div>

            <div class="section-title text-center position-relative pb-3 mb-5 mx-auto mt-2" style="font-size: 25px; max-width: 600px;">
                Fort de notre expérience en systèmes d’information, 
                nous vous garantissons des services de qualité et personnalisé.</div>

            <div class="row g-5">
                <div class="col-lg-4">
                    <div class="row g-5 Anime">

                        <div class="col-12 animated zoomIn">
                            <div class="bg-white rounded d-flex align-items-center justify-content-center mb-3" style="width: 30px; height: 30px;">
                                <div  class="choiceList_rst"></div>
                                <span class="choiceList_nd"></span>
                            </div>
                            <h4>Vous êtes unique ! Alors votre projet doit être unique </h4>
                            <p class="mb-0 nd-text">
                                C’est pour cette raison que nous mettons à votre disposition un interlocuteur
                                 unique qui va gérer à vos côtés l’ensemble de vos problématiques. <br><br>
                            </p>
                        </div>

                        <div class="col-12 animated zoomIn">
                            <div class="bg-white rounded d-flex align-items-center justify-content-center mb-3" style="width: 30px; height: 30px;">
                                <div  class="choiceList_rst"></div>
                                <span class="choiceList_nd"></span>
                            </div>
                            <h4> Notre stratégie d’action s’adapte à vous</h4>
                            <p class="mb-0 nd-text">
                                L’approche AGILES qui nous amène à concevoir notre métier différemment. Nous vous accompagnons depuis l’idée de digitalisation jusqu’à la réalisation de votre projet avec un suivi continu selon votre bon vouloir.<br><br>
                            </p>
                        </div>

                    </div>
                </div>

                <div class="col-lg-4 p-5 animated zoomIn" data-wow-delay="0.9s" style="padding-left: 60px !important; padding-right: 60px !important;  " >
                    <div class="position-relative card MonCard">
                        <img class="img-fluid Img-border m-1" src="../assets/img/accueil22.jpeg">
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="row g-5 Anime">

                        <div class="col-12 animated zoomIn">
                            <div class="bg-white rounded d-flex align-items-center justify-content-center mb-3" style="width: 30px; height: 30px;">
                                <div  class="choiceList_rst"></div>
                                <span class="choiceList_nd"></span>
                            </div>
                            <h4>Notre Expérience est votre garantie et votre bénéfice. </h4>
                            <p class="mb-0 nd-text">
                                Fort de l’expérimentation, d’épreuves, de planification, d’acquisitions
                                de savoir et succès constituent notre moule, vous donnant l’assurance de bénéficier de toute notre expertise. <br><br>
                            </p>
                        </div>

                        <div class="col-12 animated zoomIn">
                            
                            <div class="bg-white rounded d-flex align-items-center justify-content-center mb-3" style="width: 30px; height: 30px;">
                                <div  class="choiceList_rst"></div>
                                <span class="choiceList_nd"></span>
                            </div>
                            <h4>Nos métiers sont nos passions </h4>
                            <p class="mb-0 nd-text">
                                Le plaisir que nous procure notre travail restera le centre de notre collaboration. <br><br>
                            </p>
                            <div class="">
                                <router-link to="/a-propos-de-nous">
                                    <button class="mb-2 btn btn-outline-primary">En savoir plus sur Agiles Telecoms</button>
                                </router-link>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
    <!-- Features end -->


    

    <FooterView />
  </div>
</template>

<script>
// @ is an alias to /src
import NavNavbar from '@/components/NavNavbar.vue'
import FooterView from '@/components/FooterView.vue'
// import Swal from 'sweetalert2';
// import PopupAt from '@/assets/img/popupAt.jpg';

export default {
  name: 'HomeView',
  components: {
    NavNavbar,
    FooterView
  },

  data() {
  return {
      services: [
        {
          id: 1,
          titleFront: "Notre ingénierie de développement",
          descriptionFront: "Nous couvrons les principales étapes de la mise en oeuvre des applications:",
          frontItems: [
            "L'étude de faisabilité",
            "la conception fonctionnelle et technique",
            "la réalisation de l'application",
            "les textes de validation",
            "le packaging"
          ],
        },

        {
          id: 2,
          titleFront: "Consulting, Reseaux IP et Mobile",
          descriptionFront: "Agiles Télécoms vous propose une expertise dans le coeur de réseaux IP et télécoms:",
          frontItems: [
            "Ingénieurs et techniciens expérimentés en 3G,4G 5G & IMS",
            "Multi-vendors (Ericsson, Huawei, Nokia, ZTE)",
            "Experts d'équipements coeur de réseaux IP (Routeurs & Switchs)"
          ],
        },

        {
          id: 3,
          titleFront: "Consulting Dev et Système d'Information",
          descriptionFront: "Agiles Télécoms met à votre disposition des consultants qualifiés dans le domaine des réseaux Télécoms & IP:",
          frontItems: [
            "Ingénieurs et techniciens cloud (openstack, Kubernetes)",
            "Développeur (Java, Full-stack, Mobile, Android & IOS)",
            "Chefs de projets SI",
            "Ingénieur Test & Validation"
          ],
        }
        
      ]
    };
  }

//   mounted() {
//     Swal.fire({
//       html: `
//         <div>
//           <img src="${PopupAt}" alt="Vue.js Logo" style="width: 100%; background-color: green;">
//         </div>
//       `,
//       customClass: {
//         container: 'my-swal-container',
//         title: 'my-swal-title',
//         content: 'my-swal-content',
//         confirmButton: 'my-swal-confirm-button',
//         cancelButton: 'my-swal-cancel-button',
//       },
//       showConfirmButton: false,
      
//     });
//   }
}
</script>

<style scoped>

/* :root {
    --primary: #61C7F2 #F7A51B;
    --secondary: #F7A51B;
    --light: #EEF9FF;
    --dark: #091E3E;
} */
*{
    font-family: "Raleway", "Odoo Unicode Support Noto", sans-serif !important; 
}
.Anime div{
    animation-duration: 6s;
}
.fadeInU{
    animation-duration: 0.2s;
}
.About_li_fa div{
    animation-duration: 3s;
}
.About_li_fa div h5
{
    font-size: 19px;
}
h4{
    color: #F7A51B !important;
}
h5{
    color: #F7A51B !important;
}

.choiceList_rst{
    background: #61C7F2; width: 20px !important; height: 20px !important; border-radius: 100%;
}
.choiceList_nd{
    background: #F7A51B; width: 13px !important; height: 13px !important; border-radius: 100%;
}

.Img-border{
  border-style: solid;
  border-width: 2px;
  border-image: linear-gradient( #61C7F2, #F7A51B) 1;
}

.MonCard::before {
  content: "";
  height: 38%;
  width: 50px;
  background: linear-gradient(45deg, #61C7F2, #00beff);
  z-index: -1;
  left: -10px;
  top: -13px;
  position: absolute;
  transform: skew(2deg, 2deg);
}

.MonCard::after {
  content: "";
  background: linear-gradient(45deg, #F7A51B, #F7A51B);
  z-index: -1;
  height: 38%;
  width: 50px;
  right: -10px;
  bottom: -13px;
  position: absolute;
  transform: skew(2deg, 2deg);
}
/* .................................. 195.154.48.30 / zabbix / zabbix123!............................................... */




/* Title for all pages style start ################ */
  div.parent {
    width: 100%;
    display: block;
    font-size: 50px;
    text-transform: uppercase;
    text-align: center;
  }
  
  .block1 {
    font-size: 55px !important;
    z-index: 1;
    color: #1b72a111;
    font-weight: 700 !important;
  }
  
  .block2 {
    font-size: 25px;
    color: #61C7F2 !important;
    z-index: 0;
    font-weight: 900 !important;
    margin-top: -60px;
  }
  
  /* width <= 412px */
  @media screen and (max-width: 412px) {
    .block2_1 {
      margin-top: -250px !important;
    }
  }

  /* width <= 412px */
  @media screen and (max-width: 412px) {
    .block2_2 {
      margin-top: -175px !important;
    }
  }
  
  /* width >= 810px */
  @media screen and (min-width: 810px) {
    .block2_1 {
      margin-top: -92px !important;
    }
  }
/* Title for all pages style end ....................................................... */



/* Services style start ################ */
  .services-card:hover
{
    background-color: #f7a61b31 !important;
}
 
  .services-card {
    height: 370px !important;
    padding: 20px;
    border: 1px solid #61C7F2;
    border-radius: 10px;
    box-shadow: -6px -6px 0px 0px #61C7F2;
  }
  .services-card:hover {
    padding: 20px;
    border: 1px solid #F7A51B;
    border-radius: 10px;
    box-shadow: -6px -6px 0px 0px #F7A51B;
  }
  .services-card {
    background: #FFFFFF;
    font-weight: 700;
    font-size: 1rem;
  }


  .services-card hr {
    display: block;
    border: none;
    height: 3px;
    background-color: #F7A51B;
    margin: 0px;
    animation-name: line-show;
    animation-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.795, 0, 0.165, 1);
  }
  .services-card h2 {
    margin: 0px;
    padding: 0px 0px 15px 0px;
    font-size: 1.5rem;
    color: #282828;
  }

  .services-card p {
    margin: 15px 0px 0px 0px;
    font-weight: 100;
    letter-spacing: -0.25px;
    line-height: 1.25;
    font-size: 1rem;
    word-break: break-all;
    word-wrap: pre-wrap;
    color: #282828;
  }

/* Services style end ....................................................... */





.about-title-ap {
  position: relative;
  text-transform: uppercase;
  font-size: 50px;
  font-weight: 900;
  color: rgba(136, 189, 226, 0.304);
}
.about-title-ap:before {
  position: absolute;
  content: "A propos de Nous";
  color: #61C7F2;
  top: 5px;
}

.about-title-sc {
  position: relative;
  text-transform: uppercase;
  font-size: 50px;
  font-weight: 900;
  color: #f7a61b5f;
  margin: 0px !important;
  padding: 0px !important;
}
.about-title-sc:before {
  position: absolute;
  content: "Découvrez nos services";
  color: #F7A51B;
  top: 5px;
  
}


.about-title-pc {
  position: relative;
  text-transform: uppercase;
  font-size: 50px;
  font-weight: 900;
  color: #f7a61b5f;
}

.about-title-pc:before {
  position: absolute;
  content: "Pourquoi nous choisir";
  color: #F7A51B;
  top: 5px;
}


.Titre h1 {
  font-size: 50px;
  text-align: center;
  text-transform: uppercase;
  text-rendering: optimizeLegibility;
}
.retroshd {
  color: #61C7F2;
  letter-spacing: .05em;
  text-shadow: 4px 4px 0px #61c7f26e, 7px 7px 0px rgba(136, 189, 226, 0.304);
}
.retroshd:hover {
    text-decoration: none;
}
</style>