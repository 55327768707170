<template>
    <div class="Agiles_solution_items_one">
  
      <!-- Navbar & Carousel Start -->
      <div class="container-fluid position-relative p-0">
  
          <NavNavbarSI />


        <!-- AS detail Start -->
        <div class="wow fadeInUp" data-wow-delay="0.1s">
            <div class="container p-5">
                <!-- AS Detail Start -->
                <div class="mb-5" style="margin: 0px !important;">

                    <h1 class="mb-4" style="margin-bottom: 115px !important; font-size: 50px; background: linear-gradient( #61C7F2, #F7A51B ); -webkit-background-clip: text; color: transparent;">
                        <center>SS7 FIREWALL</center>
                    </h1>

                    <div>
                        <span class="Annonce mb-4"> 
                            Pare-feu multifonctionnel fiable pour les réseaux SS7 <br>

                            Prévention de toute attaque de réseau et d'abonné             
                        </span>
                    </div>
                    

                    <h3 class="Titre" style="margin-top: 65px !important; margin-bottom: 18px !important;">
                        Présentation du système
                    </h3>
                    


                    <div class="desc__titre">
                        <p class="Titre__items nd-text">
                            <span style="color: #61C7F2;"></span> 
                            PROTEI SS7 Firewall est destiné à aider les opérateurs à surveiller, contrôler et gérer le trafic SS7 avec d'autres opérateurs et
                            fournisseurs de services de télécommunications nationaux et / ou internationaux. Le pare-feu PROTEI SS7 est conçu pour détecter et traiter les messages 
                            SS7 inattendus ou non conventionnels en appliquant les règles de police MTP, SCCP, TCAP et MAP appropriées. <br><br>


                            En outre, et afin d'assurer des capacités de protection SS7 complètes, PROTEI SS7 Firewall adopte la définition GSMA des attaques SS7 spécifiée dans GSMA
                            IR.82 et dans les mises à jour des spécifications du GSMA Fraud and Security Group (FS.11, FS.07, IR .70 et IR.71). <br><br>


                            PROTEI SS7 Firewall prend en charge plusieurs approches de protection du réseau: surveillance et alerte, règles de police de base, règles de police avancées. <br><br>


                            Le système est un outil efficace pour prévenir les attaques SS7 axées sur le réseau et les abonnés telles que le spam et l'inondation, la génération de fraude, le suivi, 
                            le vol d'identité, le DoS ou l'interception illégale. Le pare-feu PROTEI SS7 peut être facilement mis à niveau pour servir les fonctionnalités SMS-FW.
                        </p>
                        
                    </div><br><br>


                    <div class="desc__avantages">

                        <h3 class="Titre">Avantages clés</h3>
                        <ul style="font-size: 21px !important; color: #052E4A;">
                            <li>Prévention garantie des attaques réseau OKSNo7 (spam, inondation, fraude)</li>
                            <li>Barrière fiable contre les attaques SS7 des abonnés (tracking, usurpation d'identité, DoS, interception)</li>
                            <li>Routage flexible et gestion des politiques individuellement pour chaque connexion SS7</li>
                            <li>Large gamme de critères de filtrage pour les messages SS7</li>
                            <li>Listes personnalisées en noir et blanc</li>
                        </ul>
                    </div>
                    
                </div>
                <!-- AS Detail End -->
            </div> 
        </div>
        <!-- AS detail End -->

          
      </div>
      <!-- Navbar & Carousel End -->
      
  
      <FooterView />
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import NavNavbarSI from '@/components/NavNavbarSI.vue'
  import FooterView from '@/components/FooterView.vue'
  
  export default {
    name: 'Agiles_solution_items_one',
    components: {
        NavNavbarSI,
      FooterView
    }
  }
  </script>
  
  <style scoped>

    .Annonce{
        font-size: 20px; font-weight: 400; 
    }
    .Titre{
        color: #F7A51B; font-weight: 500;
    }
    .Sous__titre{
        color: #052E4A; 
    }
    .Titre__items{
        font-size: 21px;
    }

  </style>