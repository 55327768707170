<template>
    <div class="Agiles_solution_items_one">
  
      <!-- Navbar & Carousel Start -->
      <div class="container-fluid position-relative p-0">
  
          <NavNavbarSI />


        <!-- AS detail Start -->
        <div class="wow fadeInUp" data-wow-delay="0.1s">
            <div class="container p-5">
                <!-- AS Detail Start -->
                <div class="mb-5" style="margin: 0px !important;">

                    <h1 class="mb-4" style="margin-bottom: 115px !important; font-size: 50px; background: linear-gradient( #61C7F2, #F7A51B ); -webkit-background-clip: text; color: transparent;">
                        <center>SMART/SAFE CITY</center>
                    </h1>

                    <div>
                        <span class="Annonce mb-4"> 
                            Créer un écosystème de ville intelligente complet <br>

                            Identifier les vulnérabilités et menaces potentielles, prédire les menaces              
                        </span>
                    </div>
                    

                    <h3 class="Titre" style="margin-top: 65px !important; margin-bottom: 18px !important;">
                        Présentation du système
                    </h3>
                    


                    <div class="desc__titre">
                        <p class="Titre__items nd-text">
                            <span style="color: #61C7F2;"></span> 
                            Le système d'information intégré Smart / Safe et le bus d'échange d'informations pour 
                            l'unité d'intervention d'urgence est une solution clé en main pour aborder la sécurité publique, 
                            la législation et la protection de l'environnement aux niveaux municipal, régional et national. 
                            La solution comprend les principaux sous-systèmes et modules suivants:

                            <ul style="font-size: 21px !important; color: #052E4A;">
                                <li>Système d'information unifié pour l'unité d'intervention d'urgence permettant <br> la réception,
                                    le suivi et la gestion des tickets d'urgence à partir de divers systèmes <br>
                                    (112/911, surveillance écologique, système d'analyse CCTV / vidéo etc.)</li>
                                <li>Bus d'échange d'informations</li>
                                <li>Sous-système d'aide à la décision</li>
                                <li>Système d'analyse d'urgence / de prévision d'urgence</li>
                            </ul>
                        </p>
                        <p class="Titre__items nd-text">
                            La solution PROTEI Smart / Safe city est conçue pour créer un écosystème de ville intelligente avancé, 
                            identifier les vulnérabilités et menaces potentielles, identifier et prédire les menaces actuelles et potentielles 
                            liées à la sécurité publique des menaces de la municipalité / ville / province, optimiser la coordination entre tous 
                            les participants pendant gestion / élimination des urgences, permettant les communications entre les participants de 
                            toutes les initiatives de sécurité publique existantes via le bus unifié.
                        </p>
                    </div><br><br>


                    <div class="desc__avantages">

                        <h3 class="Titre">Avantages clés</h3>
                        <ul style="font-size: 21px !important; color: #052E4A;">
                            <li>Outil efficace pour réduire les coûts, l'espace et la consommation d'énergie</li>
                            <li>Prend en charge une large gamme de périphériques IP tiers</li>
                            <li>Système puissant d'interaction et prise en charge de la fonctionnalité de normalisation SIP</li>
                            <li>Prise en charge des mécanismes de routage pour une interconnexion transparente des réseaux</li>
                            <li>Protection de l'interface externe contre les attaques DoS, la fraude et l'interception via IP</li>
                            <li>Prise en charge du mode de sauvegarde</li>
                            <li>Mise à l'échelle horizontale</li>
                        </ul>
                        

                    </div>
                    
                </div>
                <!-- AS Detail End -->
            </div> 
        </div>
        <!-- AS detail End -->

          
      </div>
      <!-- Navbar & Carousel End -->
      
  
      <FooterView />
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import NavNavbarSI from '@/components/NavNavbarSI.vue'
  import FooterView from '@/components/FooterView.vue'
  
  export default {
    name: 'Agiles_solution_items_one',
    components: {
        NavNavbarSI,
      FooterView
    }
  }
  </script>
  
  <style scoped>

    .Annonce{
        font-size: 20px; font-weight: 400; 
    }
    .Titre{
        color: #F7A51B; font-weight: 500;
    }
    .Sous__titre{
        color: #052E4A; 
    }
    .Titre__items{
        font-size: 21px;
    }

  </style>