<template>
  <div class="Agiles_activites">

    <!-- Navbar & Carousel Start -->
    <div class="container-fluid position-relative p-0">

        <NavNavbarSI />

        <!-- <div class="container-fluid bg-primary py-5 bg-header-ressources" style="margin-bottom: 90px;">
            <div class="row py-5">
                <div class="col-12 pt-lg-5 mt-lg-5 text-center">
                    <router-link to="/agiles-store" class="h5 text-white m-2">Agiles Store</router-link>
                    <font-awesome-icon icon="fa-regular fa-circle" :style="{ color: '#ffffff' }" />
                    <router-link to="/agiles-activites" class="h5 text-white m-2">Activités</router-link>
                </div>
                <div class="col-12 pt-lg-1 mt-lg-2 text-center text-uppercase ">
                    <router-link to="/agiles-store" class="h5 text-white m-2"><u>Découvrez aussi notre store</u></router-link>
                </div>
            </div>
        </div> -->


        <h1 class="mb-5" style="font-size: 50px; background: linear-gradient( #61C7F2, #F7A51B ); -webkit-background-clip: text; color: transparent;">
          <center>AGILES ACTIVITÉS</center> 
        </h1>
        <center><h3 class="mb-5" style="color: #052E4A;">Découvrez nos activités</h3> </center><br>


    </div>
    <!-- content End -->
    
     <!-- Blog Start -->
     <div class="container-fluid wow fadeInUp" data-wow-delay="0.1s">
        <div class="container">
            <div class="row g-5">

                <div class="container-fluid wow fadeInUp" data-wow-delay="0.1s">
                    <div class="container py-5">
                        <div class="row">
                            <!-- Blog list Start -->
                            <div class="col-lg-8">
                                <div class="row g-5">
                                   
                                <div class="col-md-6 wow slideInUp" data-wow-delay="0.1s">
                                    <div class="blog-item bg-light rounded overflow-hidden">
                                        <div class="blog-img position-relative overflow-hidden">
                                            <img class=" img-fluid" src="../../../assets/img/apropos-ac.jpg"  alt="">
                                            <a class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4" href="">Photo</a>
                                        </div>
                                        <div class="p-4">
                                            <div class="d-flex mb-3">
                                                <small class="me-3"><i class="far fa-user text-primary me-2"></i>Agiles Telecoms</small>
                                                <small><i class="far fa-calendar-alt text-primary me-2"></i>10/12/2024</small>
                                            </div>
                                            <h4 class="mb-3" style="color: #F7A51B;">Séance de photo avec l'équipe AT</h4>
                                            <p class="nd-text">
                                                Dolor et eos labore stet justo sed est sed sed sed dolor stet amet
                                                Dolor et eos labore stet justo sed est sed sed sed dolor stet amet
                                            </p>
                                            <router-link to="/details-activites" class="text-uppercase" href="">LIRE PLUS <i class="bi bi-arrow-right"></i></router-link>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 wow slideInUp" data-wow-delay="0.1s">
                                    <div class="blog-item bg-light rounded overflow-hidden">
                                        <div class="blog-img position-relative overflow-hidden">
                                            <img class="img-fluid" src="../../../assets/img/activite.jpeg" alt="">
                                            <a class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4" href="">WorkShop</a>
                                        </div>
                                        <div class="p-4">
                                            <div class="d-flex mb-3">
                                                <small class="me-3"><i class="far fa-user text-primary me-2"></i>Agiles Telecoms</small>
                                                <small><i class="far fa-calendar-alt text-primary me-2"></i>11/12/2024</small>
                                            </div>
                                            <h4 class="mb-3" style="color: #F7A51B;">WorkShop chez Orange côte d'ivoire</h4>
                                            <p class="nd-text">
                                                Dolor et eos labore stet justo sed est sed sed sed dolor stet amet
                                                Dolor et eos labore stet justo sed est sed sed sed dolor stet amet
                                            </p>
                                            <router-link to="/details-activites" class="text-uppercase" href="">LIRE PLUS <i class="bi bi-arrow-right"></i></router-link>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 wow slideInUp" data-wow-delay="0.1s">
                                    <div class="blog-item bg-light rounded overflow-hidden">
                                        <div class="blog-img position-relative overflow-hidden">
                                            <img class="img-fluid" src="../../../assets/img/activite.jpeg" alt="">
                                            <a class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4" href="">After work</a>
                                        </div>
                                        <div class="p-4">
                                            <div class="d-flex mb-3">
                                                <small class="me-3"><i class="far fa-user text-primary me-2"></i>Agiles Telecoms</small>
                                                <small><i class="far fa-calendar-alt text-primary me-2"></i>10/12/2024</small>
                                            </div>
                                            <h4 class="mb-3" style="color: #F7A51B;">After work au Green Lounge</h4>
                                            <p class="nd-text">
                                                Dolor et eos labore stet justo sed est sed sed sed dolor stet amet
                                                Dolor et eos labore stet justo sed est sed sed sed dolor stet amet
                                            </p>
                                            <router-link to="/details-activites" class="text-uppercase" href="">LIRE PLUS <i class="bi bi-arrow-right"></i></router-link>
                                        </div>
                                    </div>
                                </div>
                            <!-- Blog list end -->
                            </div>

                            </div>
                            <!-- Blog list End -->

                            <div class="col-lg-4">
                                <!-- Search Form Start -->
                                <div class="mb-5 wow slideInUp" data-wow-delay="0.1s">
                                    <div class="input-group">
                                        <input type="text" class="form-control p-3" placeholder="Mots clés">
                                        <button class="btn btn-primary px-4"><i class="bi bi-search"></i></button>
                                    </div>
                                </div>
                                <!-- Search Form End -->
    
                                <!-- Category Start -->
                                <div class="mb-5 wow slideInUp" data-wow-delay="0.1s">
                                    <div class="section-title section-title-sm position-relative pb-3 mb-4">
                                        <h3 class="mb-0">Categories</h3>
                                    </div>
                                    <div class="link-animated d-flex flex-column justify-content-start">
                                        <a class="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="#"><i class="bi bi-arrow-right me-2"></i>Photos</a>
                                        <a class="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="#"><i class="bi bi-arrow-right me-2"></i>WorkShop</a>
                                        <a class="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="#"><i class="bi bi-arrow-right me-2"></i>After work</a>
                                        <a class="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="#"><i class="bi bi-arrow-right me-2"></i>Activités</a>
                                        <a class="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="#"><i class="bi bi-arrow-right me-2"></i>Formations</a>
                                    </div>
                                </div>
                                <!-- Category End -->
                            </div>
                
                        </div>
                    </div>
                </div>
    
            </div>
        </div>
    </div>
    <!-- Blog End -->

    <FooterView />
  </div>
</template>

<script>
// @ is an alias to /src
import NavNavbarSI from '@/components/NavNavbarSI.vue'
import FooterView from '@/components/FooterView.vue'


export default {
  name: 'Agiles_activites',
  components: {
    NavNavbarSI,
    FooterView
  },



}
</script>


<style scoped>

  /* --bleu: #61C7F2;
  --orange: #F7A51B;
  --light: #EEF9FF;
  --dark: #091E3E; */


  .card {
  border: 2px solid #F7A51B;
  /* padding: 50px !important; */
  overflow: hidden;
}
.border-blue{
  border: 2px solid #61C7F2;
  padding: 5px !important;
  overflow: hidden;
}

/* ////// le modal */
.modal-window {
position: fixed;
background-color: rgba(170, 170, 170, 0.681) !important;
top: 0;
right: 0;
bottom: 0;
left: 0;
z-index: 999;
visibility: hidden;
opacity: 0;
pointer-events: none;
transition: all 0.3s;
}
.modal-window:target {
visibility: visible;
opacity: 1;
pointer-events: auto;
}
.modal-window > div {
width: 500px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
padding: 2em;
background: white;
}
.modal-window header {
font-weight: bold;
}
.modal-window h1 {
font-size: 150%;
margin: 0 0 15px;
}

.modal-close {
color: #aaa;
line-height: 50px;
font-size: 55%;
position: absolute;
right: 0;
text-align: center;
top: 0;
width: 70px;
text-decoration: none;
}
.modal-close:hover {
color: black;
}

/* Demo Styles */


.modal-window > div {
border-radius: 1rem;
}

.modal-window div:not(:last-of-type) {
margin-bottom: 15px;
}

small {
color: lightgray;
}


</style>
